/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/


/* Common style */
.item figure {
	position: relative;
	float: left;
	overflow: hidden;
	// margin: 10px 1%;
	// min-width: 320px;
	// max-width: 480px;
	// max-height: 360px;
	width: 100%;
	background: black;
	text-align: center;
	cursor: pointer;
  -webkit-clip-path: polygon(0% 0%, calc(100% - 15px) 0%, 100% calc(0% + 15px), 100% 100%, calc(0% + 15px) 100%, 0% calc(100% - 15px));
  clip-path: polygon(0% 0%, calc(100% - 15px) 0%, 100% calc(0% + 15px), 100% 100%, calc(0% + 15px) 100%, 0% calc(100% - 15px));
}

.item figure img {
	position: relative;
	display: block;
	min-height: 100%;
	max-width: 100%;
	opacity: 0.8;
  -webkit-transform: scale(1.1);
	transform: scale(1.1);
}

.item figure figcaption {
	padding: 2em;
	color: #fff;
	text-transform: uppercase;
	font-size: 1.25em;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

.item figure figcaption::before,
.item figure figcaption::after {
	pointer-events: none;
}

.item figure figcaption,
.item figure figcaption > a {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

/* Anchor will cover the whole item by default */
/* For some effects it will show as a button */
.item figure figcaption > a {
	z-index: 1000;
	text-indent: 200%;
	white-space: nowrap;
	font-size: 0;
	opacity: 0;
}

.item figure h2 {
	word-spacing: -0.15em;
	font-weight: 300;
	font-size: 1.5rem;
}

.item figure h2 span {
	font-weight: 800;
}

.item figure h2,
.item figure p {
	margin: 0;
}

.item figure p {
	letter-spacing: 1px;
	font-size: 68.5%;
}

figure.effect img {
	// max-width: none;
	// width: -webkit-calc(100% + 50px);
	// width: calc(100% + 50px);
	opacity: 1;
	-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
	transition: opacity 0.35s, transform 0.35s;
	// -webkit-transform: translate3d(-40px,0, 0);
	// transform: translate3d(-40px,0,0);
}

figure.effect figcaption {
	text-align: left;
}

figure.effect figcaption > div {
	position: absolute;
	bottom: 0;
	left: 0;
	padding: 2em;
	width: 100%;
	height: 50%;
}

figure.effect h2,
figure.effect p {
	-webkit-transform: translate3d(0,40px,0);
	transform: translate3d(0,40px,0);
}

figure.effect h2 {
	-webkit-transition: -webkit-transform 0.35s;
	transition: transform 0.35s;
}

figure.effect p {
	color: rgba(255,255,255,0.8);
	opacity: 0;
	-webkit-transition: opacity 0.2s, -webkit-transform 0.35s;
	transition: opacity 0.2s, transform 0.35s;
}

figure.effect:hover img{
	opacity: .7;
}
figure.effect:hover p {
  opacity: 1;
}

figure.effect:hover img,
figure.effect:hover h2,
figure.effect:hover p {
	-webkit-transform: translate3d(0,0,0);
	transform: translate3d(0,0,0);
}

figure.effect:hover p {
	-webkit-transition-delay: 0.05s;
	transition-delay: 0.05s;
	-webkit-transition-duration: 0.35s;
	transition-duration: 0.35s;
}